import React, { useState } from 'react';

import map from "../images/icons/map.svg"
import phone from "../images/icons/phone.svg"
import clock from "../images/icons/clock.svg"

const days = {
  monday: "lundi",
  tuesday: "mardi",
  wednesday: "mercredi",
  thursday: "jeudi",
  friday: "vendredi",
  saturday: "samedi",
}

export default function ContactForm({ horairesBureau }) {

  const [isSending, setIsSending] = useState(false);
  const [formMessage, setFormMessage] = useState('');


  const handleSubmit = (e) => {
    e.preventDefault()
    setIsSending(true);

    let myForm = e.target;
    let formData = new FormData(myForm)
    fetch('/', {
      method: 'POST',
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    }).then(() => {
      setIsSending(false);
      myForm.reset();
      setFormMessage('Votre message a bien été envoyé, nous reviendrons vers vous prochainement.')
    })
    .catch((error) => {
      console.error(error)
      setFormMessage(`Une erreur est survenue lors de l'envoi de votre message. Veuillez réessayer ou écrire à contact@yvanblin.com.`)
    })
  }

  return (
    <div className={`${isSending ? 'animate-pulse' : ''} grid md:grid-cols-2 gap-6`}>
      <div className="md:w-4/5 xl:w-3/5 mb-12 md:mb-0">
        <form name="contact" method="POST" data-netlify="true" onSubmit={handleSubmit} enctype="multipart/form-data">
          <input type="hidden" name="form-name" value="contact" />
          <div className="mt-4">
            <label className="block" htmlFor="name">
              Votre nom
            </label>
            <input type="text" name="name" id="name" className="input" />
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="email">
              Votre email
            </label>
            <input type="email" name="email" id="email" className="input" />
          </div>
          <div className="mt-4">
            <label className="block" htmlFor="message">
              Votre message
            </label>
            <textarea id="message" name="message" className="input"></textarea>
          </div>
          <div className="flex items-start mt-5">
            <button type="submit" className="btn">
              Envoyer
            </button>
            <p className="ml-3 text-sm">{formMessage}</p>
          </div>
        </form>
      </div>

      <div>
        <div className="flex mt-8">
          <img
            src={phone}
            width="24"
            className="inline-block mr-8 self-start"
            alt=""
          />
          <div>02 99 74 40 48</div>
        </div>
        <div className="flex mt-8">
          <img
            src={map}
            width="20"
            className="inline-block mr-8 self-start"
            alt=""
          />
          <div>
            <p>
              3 boulevard Louis Giroux
              <br />
              35500 Vitré
            </p>
          </div>
        </div>
        <div className="flex mt-8">
          <img
            src={clock}
            width="24"
            className="inline-block mr-8 self-start"
            alt=""
          />
          <div>
            {Object.entries(days).map(day => (
              <p>
                <span className="capitalize inline-block w-28">{day[1]}</span> {horairesBureau[day[0]]}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
