import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Breadcrumb from "../components/breadcrumb"
import ContactForm from "../components/contactForm"

const ContactPage = ({data}) => (
  <Layout>
    <Seo title="Nous contacter" breadcrumb={[
      { url: "contact", name: "Nous contacter"},
    ]} />
    <Breadcrumb paths={[{url: null, name:"Nous contacter"}]} />
    <h1 className="h1">Nous contacter</h1>
    <ContactForm horairesBureau={data.strapiHorairesDeBureau}/>
  </Layout>
)

export default ContactPage


export const pageQuery = graphql`
  query ContactQuery {
    strapiHorairesDeBureau {
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
    }
  }
`
